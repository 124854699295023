import React from 'react'

import Layout from '../components/Layout'

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <h1>Такой страницы не существует</h1>
      </Layout>
    )
  }
}

export default NotFoundPage
